import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/CaseStudyLayout/CaseStudyLayout.tsx";
import Helmet from 'react-helmet';
import CaseStudyIntro from '../components/CaseStudyIntro/CaseStudyIntro';
import CaseStudyConclusion from '../components/CaseStudyConclusion/CaseStudyConclusion';
import NextCaseStudy from '../components/NextCaseStudy/NextCaseStudy';
import Wrapper from '../components/Wrapper/Wrapper';
import Container from '../components/Container/Container';
import SpruceLabsColorSection from '../components/SpruceLabs/SpruceLabsColorSection';
import SpruceLabsWordsSection from '../components/SpruceLabs/SpruceLabsWordsSection';
import heroImage from '../images/Spruce-Labs-Hero@2x.png';
import shareImage from '../images/Spruce-Labs-Thumb@2x.png';
import cinebodyThumb from '../images/Cinebody-Thumb@2x.png';
import logoLoop from './spruce-labs/Logo-Loop.gif';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet meta={[{
      property: 'og:image',
      content: `https://tinyeahno.com${shareImage}`
    }, {
      name: 'twitter:image',
      content: `https://tinyeahno.com${shareImage}`
    }]} mdxType="Helmet" />
    <CaseStudyIntro title="Spruce Labs" subtitle="Visual identity refresh for an up and coming technology startup." heroKey="sprucelabs" content={[{
      kind: 'text',
      headline: 'The Brief',
      text: "Founded in 2016, Spruce Labs began with the creation of Spruce: an experience management platform for brick and mortar retail. I joined the team in 2018 and after creating the Heartwood design system I shifted focus to the company’s visual identity."
    }, {
      kind: 'text',
      text: "As the product evolved, the previous identity fell out of sync with the company’s design principles. My goal was to bring the two back into alignment."
    }]} aside={[{
      headline: 'My Role',
      items: ['Design Strategy', 'Visual Design', 'Illustration']
    }]} mdxType="CaseStudyIntro" />
    <Wrapper mdxType="Wrapper">
    <Container className="ta-center" mdxType="Container">
        <img src={logoLoop} className="img-scale" width={400} height={300} />
    </Container>
    </Wrapper>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAw0lEQVQoz5WSzRKCIBRGef9HaiZXtWrRWG2aVv2YkIpjO1HujVBMkLAYv4G5HI4MQGohUEqJABCMEI3iYJYj6DQz4dZ+5UhXhO4bAXoMnsVeDlzhR1C3jUrb/wMmQiNo1TG9g87cIJQgdR+VF4z42aqNhbLvV5yrFFZtEBqY8xLXhx1uTkfM8sKSjbn0TnGxjXEZ75FSZnGWsKqemFwTZCnT429C9sgUd9PJCz4Vhm7Se4YBjrg35u7G90RCHJl7qP/mBae1FJ+/ydB+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7c4f04097f1b58275569324aa063d7fb/e93cc/Logo-Before-After%402x.webp 300w", "/static/7c4f04097f1b58275569324aa063d7fb/b0544/Logo-Before-After%402x.webp 600w", "/static/7c4f04097f1b58275569324aa063d7fb/68fc1/Logo-Before-After%402x.webp 1200w", "/static/7c4f04097f1b58275569324aa063d7fb/a2303/Logo-Before-After%402x.webp 1800w", "/static/7c4f04097f1b58275569324aa063d7fb/4293a/Logo-Before-After%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7c4f04097f1b58275569324aa063d7fb/aaafa/Logo-Before-After%402x.png 300w", "/static/7c4f04097f1b58275569324aa063d7fb/8db2a/Logo-Before-After%402x.png 600w", "/static/7c4f04097f1b58275569324aa063d7fb/6ca50/Logo-Before-After%402x.png 1200w", "/static/7c4f04097f1b58275569324aa063d7fb/4d07a/Logo-Before-After%402x.png 1800w", "/static/7c4f04097f1b58275569324aa063d7fb/ee4de/Logo-Before-After%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7c4f04097f1b58275569324aa063d7fb/6ca50/Logo-Before-After%402x.png",
              "alt": "Comparison of the old and new logos",
              "title": "Making the Spruce logo smile.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Making the Spruce logo smile.`}</figcaption>{`
  `}</figure></p>
    <h2>{`A Smiling Logo`}</h2>
    <p>{`The old logo always felt a bit off, for reasons the team found hard to vocalize. Looking at it through the lens of Heartwood's Humanity principle made it clear. The logo was frowning. The solution was to make it smile.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAq0lEQVQoz62SzQ6CMBCE+/5P6EkPmhCoyMET7XZH+qdLuxIPbjKQLh/LtINhZvQC9H7L9JyJF1khhCRZFZbryrX9NJDzCnHM8Fhg7f0Ntg5iPZ3DMFnM8wIi2nE7hywcls8cOtT6RnOgOdMYjesHFvEP0jgjzyoWFeUj4G7L9R4kJ56VgZ8XzuOU9G3bqb8FcbrecNmCabkuFHIe5D344LeJgbh1zdw/QjniXsjXGPVOTJviAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cab4f5e215ba0de8f512ee49742a6fea/e93cc/Lockups-Subbrands%402x.webp 300w", "/static/cab4f5e215ba0de8f512ee49742a6fea/b0544/Lockups-Subbrands%402x.webp 600w", "/static/cab4f5e215ba0de8f512ee49742a6fea/68fc1/Lockups-Subbrands%402x.webp 1200w", "/static/cab4f5e215ba0de8f512ee49742a6fea/a2303/Lockups-Subbrands%402x.webp 1800w", "/static/cab4f5e215ba0de8f512ee49742a6fea/4293a/Lockups-Subbrands%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cab4f5e215ba0de8f512ee49742a6fea/aaafa/Lockups-Subbrands%402x.png 300w", "/static/cab4f5e215ba0de8f512ee49742a6fea/8db2a/Lockups-Subbrands%402x.png 600w", "/static/cab4f5e215ba0de8f512ee49742a6fea/6ca50/Lockups-Subbrands%402x.png 1200w", "/static/cab4f5e215ba0de8f512ee49742a6fea/4d07a/Lockups-Subbrands%402x.png 1800w", "/static/cab4f5e215ba0de8f512ee49742a6fea/ee4de/Lockups-Subbrands%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cab4f5e215ba0de8f512ee49742a6fea/6ca50/Lockups-Subbrands%402x.png",
              "alt": "Logos and lockups for Spruce and Spruce Labs",
              "title": "Spruce lockups (left) with Spruce Labs as an example of a sub-brand (right).",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Spruce lockups (left) with Spruce Labs as an example of a sub-brand (right).`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABaUlEQVQoz42SWU/CQBSF+zuMskuRvWUTsKiJRhNNjM/+bY0vviKIIUIXa9pOj3OHMkyJD04ymTvT3C/n3FMtjmNs17ZW32jZjoO6OcFRsYW83hE7VzblmT025F2jBsZiRFGUgjHG4Ps/onYclwMtZEptlKr9FKx40oPeGMq7AAZhCM/zUsAgCDGbfyDk31zXQ804E011wxKKqKaz2h5jYN0IsAD+13Ijsayq20DN1LvGeMNibWOx+JS2aX2tVpi+z7jCiCt0UeNAvXuOU+tWqqFt9C8xvrgTtkmxRloIFHFrqsIoYgKmzjDLAWU+Q1UhwWkXKt3dDKnN37NL57fvK5YtHBTbyFAoyQy30EpzJOcqgNPlEm+zeQpo2w6eX15FTaFUWyM0hlfoXT8gnzRT6s3OBPePT7tQhEJuNwyCDSwB0hgoadVygUP02mDzLyYzJFBJGYO2n2j8R8pOkvJhoSXDyOnqj21K+79nUiC3ULYjvAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/9bae688a99de67f690807a9b1cb50938/e93cc/Day-Night%402x.webp 300w", "/static/9bae688a99de67f690807a9b1cb50938/b0544/Day-Night%402x.webp 600w", "/static/9bae688a99de67f690807a9b1cb50938/68fc1/Day-Night%402x.webp 1200w", "/static/9bae688a99de67f690807a9b1cb50938/a2303/Day-Night%402x.webp 1800w", "/static/9bae688a99de67f690807a9b1cb50938/4293a/Day-Night%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/9bae688a99de67f690807a9b1cb50938/aaafa/Day-Night%402x.png 300w", "/static/9bae688a99de67f690807a9b1cb50938/8db2a/Day-Night%402x.png 600w", "/static/9bae688a99de67f690807a9b1cb50938/6ca50/Day-Night%402x.png 1200w", "/static/9bae688a99de67f690807a9b1cb50938/4d07a/Day-Night%402x.png 1800w", "/static/9bae688a99de67f690807a9b1cb50938/ee4de/Day-Night%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9bae688a99de67f690807a9b1cb50938/6ca50/Day-Night%402x.png",
              "alt": "Logos and lockups in day mode and night mode",
              "title": "Day and night contexts to match the core product.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Day and night contexts to match the core product.`}</figcaption>{`
  `}</figure></p>
    <SpruceLabsColorSection mdxType="SpruceLabsColorSection" />
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADf0lEQVQozx2S6U/bBRjH+1foiyUmHpMh6CYMEGgLWNoxxtkiZRQYx7iPMlbS0iLrgC3rFpJNndMXGs3cOObkaqHjKldAzrKWM0DUCVPmLRkz8efvY/XFJ3leffL9Ps8jeWqOFvZM8YK2yisoijcFZemWoC5xC7MpMcKyJlJYTIoQlpIjhJ2eVmHDcV9IN18UEo1WIdXSLLxdWSccCkkVXgjT+FALzx1LECT7F1T80JCCusxDgfVbCpsekVToZlKtZDFDwUruCdZzlezab7PuaOPspavkNF+j4PIV1EYLgYp0jsfpfOQQEKVFcjB9TXwydkPUVG+IVbYdUX91R1QVLIllISrRJJWL3zvzxL2JIvHnuWpxf9ksPphyiH0TveKe+7z4p/ec+Ku7Svxxplr0dt4WlzvbRcn2oI2H9hZSKt1E53tI1G+SWuGmUqnFnJTKsrOUtZFy1p35fDdezsBIJw9GuuCbOrZHithyFf8/P3be5XF/B5LX5GkcVWTwUkQGr8p19I0PMb4wydi0k76hbpTJdcgVeownE7mQnILx1DuYE1L4y6vH01/Aor0ANs5xcCmJZ9ZTSPzlWoJUORyWZeInz2RqycVnX93l8642ph9OEKK0EhhWy1lZGoZ4HfrYNKoVCexOVnCwauCPlRp+n6/kkVnJTq0CyXpPG3Md7aRWeVCfX6HXNcCn977gk4479LgGyTStoalZI9OwQnLJHIePVeD3ZglBUj1HZRVoTA3orI1kW6yk1dYh2XW0stHZQVHdGnnGFexjgwz66k67B3DNDJNvWSXXvEF+/SbaGi+HAvVo86+TduY6z/sXk25p5kyTjaxGG5r6RiTu+CAWYgOZkAYwEBtKurGBrKYmflqq5Zf5Gkoi40j3k5MVEMXpN5SERtWS7hOqs1uQqkxsDbWwv/A+v82+x5OpG0hmwl/EkxPv+7WTTMpeJr7cSKLBwupQCdvDpZQGv4Ul+TRWTSa6I2GERhsIjTUTHGMiUmXk6fxN1vpteO1XYOUWklnpK6xW6tio0uGK8EOqMBAWV8+J1HeJiTOQeSQcW14Zl3NKKTwezZf2+9xzdtPu6KJvpJe/vR8z2noRV2sjgudDJPMx/vwnXfSlG5UHEB1rRJ7QSGSclWBZLdmvy8gLCCPbL4Sy8Fgcw92+PTt8B7Mz+rWTg6WP+Mdz08ctni1+wL8qRmRfhbKG2AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/e93cc/Color-02%402x.webp 300w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/b0544/Color-02%402x.webp 600w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/68fc1/Color-02%402x.webp 1200w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/a2303/Color-02%402x.webp 1800w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/4293a/Color-02%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/aaafa/Color-02%402x.png 300w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/8db2a/Color-02%402x.png 600w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/6ca50/Color-02%402x.png 1200w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/4d07a/Color-02%402x.png 1800w", "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/ee4de/Color-02%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d1ecdcaba9aa0b8aaa3f1d2db9eaa34a/6ca50/Color-02%402x.png",
              "alt": "More refined color options based on temperature and value contrast.",
              "title": "More refined color options based on temperature and value contrast.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`More refined color options based on temperature and value contrast.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Bridging the Gap`}</h2>
    <p>{`Illustrations bridge the gap between visual identity and product. We focused on interjecting playful illustrations at moments when a user experiences success`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACGklEQVQoz52TX2vTUBjG8zE69WLolxB27VRweOWN4oUfwhuv3LxxbDBlgoKidmgFYXXVKWO2mzdtmeDQ1nZt1qydRW1Zu6RNmubPyXl8T5IquvViHvjlPTnJeXie8ybSyUSCjSWTbCKdZhfSGaqZv+q/TBwyv5jNsrFUigktaXT5Da48iuLO3H1Mzd7DrZn5IzE1O4/p23dx9eETjL5dhnR8aYnPbXzkP5UqL2yVeakkH0KZ579u8Xw+IJcrEgWeyxf5Z6JalPlMOstPkJYUWVzEy3od/ztYWGO1XYyQljQSj+PZzo6/6A3Z5Hke+pbjY9kuNr8U8X49i0azBZPuxcbodgVCy3f4olYDvYnvjSZUVUW73UKrtUdzDarW8QVth8FxGQzTwvUbk7h0+RrerX5An9bhelioKDgmBIXNWLXqO1E7XTT3NPxoqsQ+tI4Oy7LAOfcFLTsQLch1rG+U0en2oHYNgMMX9B2Ky/Mwss08PHjdwM2n3zAZraO0a4aRA0HBPiWIvspiOqagUmugR8mEYHRb+RN5cIYORetZFKvPoJsMLuOBYOjQpDPskKPNvIzMpxLamhFEDgUjA4eDyGLjsOHQRpfOStdNMErCybWoflMQRI7Ewy4vKIq/aLouxWa/cQSiIVQNcmf0bfRsBzrVARo1SXw7j+Wwy6cSCZxeWcH5tTWMp1I4O4TxpCB5gDPEOXouNOjXwy+bmi1SJ9/zuAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/694e2bd2b9910e05b559703d5615ad13/e93cc/App%402x.webp 300w", "/static/694e2bd2b9910e05b559703d5615ad13/b0544/App%402x.webp 600w", "/static/694e2bd2b9910e05b559703d5615ad13/68fc1/App%402x.webp 1200w", "/static/694e2bd2b9910e05b559703d5615ad13/a2303/App%402x.webp 1800w", "/static/694e2bd2b9910e05b559703d5615ad13/4293a/App%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/694e2bd2b9910e05b559703d5615ad13/aaafa/App%402x.png 300w", "/static/694e2bd2b9910e05b559703d5615ad13/8db2a/App%402x.png 600w", "/static/694e2bd2b9910e05b559703d5615ad13/6ca50/App%402x.png 1200w", "/static/694e2bd2b9910e05b559703d5615ad13/4d07a/App%402x.png 1800w", "/static/694e2bd2b9910e05b559703d5615ad13/ee4de/App%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/694e2bd2b9910e05b559703d5615ad13/6ca50/App%402x.png",
              "alt": "A screenshot of one of our new brand illustrations in the browser.",
              "title": "Illustration sample in the core app.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Illustration sample in the core app.`}</figcaption>{`
  `}</figure></p>
    <SpruceLabsWordsSection mdxType="SpruceLabsWordsSection" />
    <CaseStudyConclusion headline="Results" text="The refreshed identity has expanded Spruce Labs’s ability to scale as the company grows. As new challenges come into focus, the team will be prepared to meet them." mdxType="CaseStudyConclusion" />
    <NextCaseStudy slug="cinebody" image={{
      src: cinebodyThumb,
      width: 736,
      height: 460
    }} title="Cinebody" description="Web app design for a global content creator" tags='Digital Product Design, Frontend Development' mdxType="NextCaseStudy" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      